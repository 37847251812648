body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary {
  background-color: #d6006e;
}


:where(.css-dev-only-do-not-override-10ed4xt).ant-layout-header {
  background-color: #d6006e;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-menu-light .ant-menu-item-selected {
  color: #d6006e;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-menu-light .ant-menu-item-selected {
  background-color: #FFF0F5;
}

:where(.css-dev-only-do-not-override-1me4733).ant-layout-header {
  background-color: #d6006e;
}

:where(.css-dev-only-do-not-override-1me4733).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1me4733).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 150px;
  height: 150px;
}

.tbl_border {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}