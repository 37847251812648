.display-container {
    padding: 20px;
    background-color: #C30769;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 20px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.product-list .ant-list-item {
    padding: 0;
    width: 100%;
}

.product-card {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    background-color: #FFF;
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.product-info {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.product-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.product-details {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #666;
}

.discount-badge {
    background-color: #D6006E;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
}

.product-qty {
    font-size: 14px;
    color: #666;
}

.product-price {
    color: #FF4500;
    font-size: 14px;
    font-weight: bold;
}

.total-amount {
    text-align: right;
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    padding: 10px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-container h1 {
    color: #333;
    font-size: 22px;
    font-weight: bold;
}

.carousel-slide img {
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-info, .voucher-point-info {
    padding: 10px;
    background-color: #FFF;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.payment-info h5, .voucher-point-info h5 {
    font-size: 14px;
    color: #333;
    margin: 0;
}
